export const formatDate = (dateString) => {
  if (!dateString) return '-';
  
  // Проверяем, является ли строка форматом ddmmyyyy
  if (dateString.length === 8 && !isNaN(dateString)) {
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    return `${day}.${month}.${year}`;
  }
  
  // Если это не формат ddmmyyyy, пробуем преобразовать как обычную дату
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '-';
    return date.toLocaleString();
  } catch (e) {
    return '-';
  }
}; 
import { Header } from './Header';
import { Footer } from './Footer';

export const PublicLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow p-4">
        {children}
      </main>
      {/* <Footer /> */}
    </div>
  );
}; 
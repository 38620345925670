import { useState, forwardRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { HostCard } from './HostCard';
import { AddHostModal } from './AddHostModal';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { ref as databaseRef, get, child, onValue, off, update, set } from 'firebase/database';
import { 
  PlusCircleIcon, 
  ViewColumnsIcon, 
  TagIcon, 
  TableCellsIcon, 
  Squares2X2Icon,
  PencilIcon,
  TrashIcon,
  ClipboardIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';
import { useLocale } from '../contexts/LocaleContext';
import { useTranslation } from 'react-i18next';
import { TAG_COLORS } from '../constants/colors';
import debug from '../utils/debug';
import { cacheManager } from '../utils/cache';
import { toast } from 'react-hot-toast';
import { formatDate } from '../utils/date';

const EmptyState = ({ onAddClick }) => {
  const { t } = useLocale();
  
  return (
    <div className="flex flex-col items-center justify-center h-full py-8">
      <div className="flex justify-center">
        <div className="relative">
          <div className="absolute -inset-4">
            <div className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-blue-400 to-purple-400" />
          </div>
          <PlusCircleIcon className="relative w-24 h-24 text-blue-500" />
        </div>
      </div>
      <h3 className="mt-6 text-xl font-semibold text-gray-900">
        {t('hosts.empty.title')}
      </h3>
      <p className="mt-2 text-sm text-gray-600 max-w-sm mx-auto text-center">
        {t('hosts.empty.description')}
      </p>
      <div className="mt-6">
        <button
          onClick={onAddClick}
          className="inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusCircleIcon className="w-5 h-5" />
          {t('hosts.empty.addButton')}
        </button>
      </div>
    </div>
  );
};

// Добавляем компоненты модальных окон
const EditIpModal = ({ hostId, currentIp, onClose, onUpdate }) => {
  const { t } = useLocale();
  const [newIp, setNewIp] = useState(currentIp || '');
  const [ipError, setIpError] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  const validateIpv4 = (value) => {
    if (!value) return 'IP адрес обязателен';

    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (!ipv4Regex.test(value)) {
      return 'Неверный формат IP адреса';
    }

    const octets = value.split('.');
    const isValid = octets.every(octet => {
      const num = parseInt(octet, 10);
      return num >= 0 && num <= 255;
    });

    if (!isValid) {
      return 'Каждое число должно быть от 0 до 255';
    }

    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateIpv4(newIp);
    if (validationError) {
      setIpError(validationError);
      return;
    }

    setIsUpdating(true);
    try {
      await onUpdate(hostId, newIp);
      onClose();
    } catch (error) {
      console.error('Error updating IP:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-lg mx-auto p-6">
        <h3 className="text-lg font-semibold mb-4">{t('hosts.card.ipAddress')}</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              value={newIp}
              onChange={(e) => {
                setNewIp(e.target.value);
                setIpError('');
              }}
              className={`w-full px-3 py-2 border rounded-lg ${
                ipError ? 'border-red-300' : 'border-gray-300'
              }`}
              placeholder="Enter IP address"
            />
            {ipError && <p className="mt-1 text-sm text-red-500">{ipError}</p>}
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-700"
            >
              {t('tooltips.hosts.cancel')}
            </button>
            <button
              type="submit"
              disabled={isUpdating}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
            >
              {isUpdating ? 'Updating...' : t('tooltips.hosts.save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EditTagModal = ({ hostId, currentTag, currentColor, onClose, onUpdate }) => {
  const { t } = useLocale();
  const [newTag, setNewTag] = useState(currentTag || '');
  const [tagColor, setTagColor] = useState(currentColor || TAG_COLORS[0].id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onUpdate(hostId, newTag, tagColor);
      onClose();
    } catch (error) {
      console.error('Error updating tag:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-lg mx-auto p-6">
        <h3 className="text-lg font-semibold mb-4">{t('hosts.card.tag')}</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder={t('hosts.card.tagPlaceholder')}
          />
          <div className="flex gap-2">
            {TAG_COLORS.map(color => (
              <button
                key={color.id}
                type="button"
                onClick={() => setTagColor(color.id)}
                className={`w-8 h-8 rounded-full ${color.bg} ${
                  tagColor === color.id ? 'ring-2 ring-offset-2 ring-gray-400' : ''
                }`}
              />
            ))}
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-700"
            >
              {t('tooltips.hosts.cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {t('tooltips.hosts.save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EditNotesModal = ({ hostId, currentNotes, onClose, onUpdate }) => {
  const { t } = useLocale();
  const [notes, setNotes] = useState(currentNotes || '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onUpdate(hostId, notes);
      onClose();
    } catch (error) {
      console.error('Error updating notes:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-lg mx-auto p-6">
        <h3 className="text-lg font-semibold mb-4">{t('hosts.card.notes')}</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full h-32 px-3 py-2 border border-gray-300 rounded-lg resize-none"
            placeholder={t('hosts.card.notesPlaceholder')}
          />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-700"
            >
              {t('tooltips.hosts.cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {t('tooltips.hosts.save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const HostsList = forwardRef(({ onDeleteHost, onUpdateHost, onAddHost }, ref) => {
  const { t } = useLocale();
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const [showAddModal, setShowAddModal] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cachedHosts, setCachedHosts] = useState({});
  const [viewMode, setViewMode] = useState('grid');
  const [groupByTags, setGroupByTags] = useState(false);
  const [savedView, setSavedView] = useState(null);
  const [editingHostId, setEditingHostId] = useState(null);
  const [editingTagHostId, setEditingTagHostId] = useState(null);
  const [editingNotesHostId, setEditingNotesHostId] = useState(null);
  const [deletingHostIds, setDeletingHostIds] = useState(new Set());

  const handleCacheError = (error) => {
    console.error('Cache error:', error);
    
    let errorMessage = t('errors.cacheError');
    
    if (error.name === 'QuotaExceededError') {
      errorMessage = t('errors.cache.write');
    } else if (error.name === 'DataCloneError') {
      errorMessage = t('errors.cache.invalid');
    }
    
    toast.error(errorMessage);
  };

  const handleDeleteHost = async (hostId) => {
    try {
      setDeletingHostIds(prev => new Set([...prev, hostId]));
      const host = hosts.find(h => h.id === hostId);
      if (!host) return;

      const response = await fetch('/delhost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hostname: host.name,
          uid: user.uid
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete host');
      }

      // Удаляем хост из Firebase
      const hostRef = databaseRef(db, `posts/${hostId}`);
      await set(hostRef, null);

      // Удаляем связь с пользователем
      const userPostRef = databaseRef(db, `user-posts/${user.uid}/${hostId}`);
      await set(userPostRef, null);
      
      // После успешного удаления с сервера обновляем локальное состояние
      const updatedHosts = hosts.filter(h => h.id !== hostId);
      setHosts(updatedHosts);
      
      try {
        setCachedHosts(prevCache => {
          const newCache = { ...prevCache };
          delete newCache[hostId];
          return newCache;
        });
        
        cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
      } catch (cacheError) {
        console.error('Cache update error:', cacheError);
        toast.error(t('errors.cache.write'));
      }
      
      toast.success("Host successfully deleted");
    } catch (error) {
      console.error('Error deleting host:', error);
      toast.error("Error deleting host");
    } finally {
      setDeletingHostIds(prev => {
        const next = new Set(prev);
        next.delete(hostId);
        return next;
      });
    }
  };

  useEffect(() => {
    debug('Fetching hosts...');
    
    if (user?.uid) {
      const userPostsRef = databaseRef(db, `user-posts/${user.uid}`);
      const postsRef = databaseRef(db, 'posts');
      const unsubscribers = [];

      // Подписываемся на изменения в списке постов пользователя
      const unsubscribeUserPosts = onValue(userPostsRef, (snapshot) => {
        const userPosts = snapshot.val() || {};
        
        // Отписываемся от старых подписок
        unsubscribers.forEach(unsub => unsub());
        unsubscribers.length = 0;

        // Получаем текущие ID постов
        const currentPostIds = Object.keys(userPosts);
        
        // Если постов нет, очищаем список хостов и завершаем загрузку
        if (currentPostIds.length === 0) {
          setHosts([]);
          setLoading(false);
          return;
        }
        
        // Удаляем хосты, которых больше нет в userPosts
        setHosts(prevHosts => {
          const updatedHosts = prevHosts.filter(host => currentPostIds.includes(host.id));
          
          // Обновляем кэш при изменении списка хостов
          try {
            cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
            setCachedHosts(prevCache => {
              const newCache = { ...prevCache };
              updatedHosts.forEach(host => {
                newCache[host.id] = host;
              });
              return newCache;
            });
          } catch (cacheError) {
            console.error('Cache update error:', cacheError);
            toast.error(t('errors.cache.write'));
          }
          
          return updatedHosts;
        });

        let loadedPosts = 0;
        // Создаем новые подписки для каждого поста
        currentPostIds.forEach(postId => {
          const postRef = databaseRef(db, `posts/${postId}`);
          const unsubscribe = onValue(postRef, (postSnapshot) => {
            const postVal = postSnapshot.val();
            if (postVal) {
              setHosts(prevHosts => {
                const newHosts = prevHosts.filter(h => h.id !== postId);
                const updatedHosts = [...newHosts, {
                  id: postId,
                  name: postVal.title || '',
                  ip: postVal.ip || '',
                  tag: postVal.tag || '',
                  tagColor: postVal.tagColor || TAG_COLORS[0].id,
                  notes: postVal.notes || '',
                  lastUpdate: userPosts[postId].updated || null
                }].sort((a, b) => a.name.localeCompare(b.name));
                
                // Обновляем кэш
                try {
                  cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
                  setCachedHosts(prevCache => {
                    return updatedHosts.reduce((acc, host) => {
                      acc[host.id] = host;
                      return acc;
                    }, {});
                  });
                } catch (cacheError) {
                  console.error('Cache update error:', cacheError);
                  toast.error(t('errors.cache.write'));
                }
                
                loadedPosts++;
                // Если загрузили все посты, завершаем загрузку
                if (loadedPosts === currentPostIds.length) {
                  setLoading(false);
                }
                
                return updatedHosts;
              });
            } else {
              loadedPosts++;
              // Если загрузили все посты, завершаем загрузку
              if (loadedPosts === currentPostIds.length) {
                setLoading(false);
              }
            }
          });
          unsubscribers.push(unsubscribe);
        });

      }, (error) => {
        debug('Error fetching hosts:', error);
        setError(error.message);
        setLoading(false);
        toast.error(t('errors.loadingHosts'));
      });

      return () => {
        unsubscribeUserPosts();
        unsubscribers.forEach(unsub => unsub());
      };
    }
  }, [user?.uid]);

  const handleAdd = async (hostData) => {
    try {
      await onAddHost(hostData);
      // toast.success(t('notifications.hosts.added'));
      setShowAddModal(false);
    } catch (error) {
      console.error('Error adding host:', error);
      toast.error(t('hosts.errors.addHost.default'));
    }
  };

  const handleUpdateClick = (hostId) => {
    setEditingHostId(hostId);
  };

  const handleUpdateTagClick = (hostId) => {
    setEditingTagHostId(hostId);
  };

  const handleUpdateNotesClick = (hostId) => {
    setEditingNotesHostId(hostId);
  };

  const handleUpdateComplete = async (hostId, newIp) => {
    try {
      const host = hosts.find(h => h.id === hostId);
      if (!host) return;

      const response = await fetch('/updatehost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hostname: host.name,
          ip: newIp,
          uid: user.uid
        })
      });

      if (!response.ok) {
        throw new Error(await response.text() || 'Failed to update IP');
      }

      // Обновляем состояние без показа уведомления
      await onUpdateHost(hostId, newIp, false);
      setEditingHostId(null);
      
      // Показываем уведомление только здесь
      if (i18n.isInitialized) {
        toast.success(t('notifications.ipUpdated'));
      } else {
        toast.success('IP address successfully updated');
      }
    } catch (error) {
      console.error('Error updating host:', error);
      toast.error(t('notifications.hosts.updateError'));
    }
  };

  useImperativeHandle(ref, () => ({
    openAddModal: () => {
      setShowAddModal(true);
    }
  }));

  // Загружаем сохраненные настройки отображения
  useEffect(() => {
    const loadSavedView = async () => {
      if (user?.uid) {
        const viewRef = databaseRef(db, `user-settings/${user.uid}/hostView`);
        const snapshot = await get(viewRef);
        if (snapshot.exists()) {
          const savedSettings = snapshot.val();
          setSavedView(savedSettings);
          setGroupByTags(savedSettings.groupByTags || false);
          setViewMode(savedSettings.viewMode || 'grid');
        }
      }
    };
    loadSavedView();
  }, [user?.uid]);

  // Сохраняем настройки отображения
  const saveViewSettings = async () => {
    if (user?.uid) {
      const viewRef = databaseRef(db, `user-settings/${user.uid}/hostView`);
      await set(viewRef, {
        groupByTags,
        viewMode
      });
      toast.success(t('notifications.viewSaved'));
    }
  };

  // Группируем хосты по тегам
  const groupedHosts = useMemo(() => {
    if (!groupByTags) return { ungrouped: hosts };
    
    return hosts.reduce((groups, host) => {
      const tag = host.tag || 'ungrouped';
      if (!groups[tag]) groups[tag] = [];
      groups[tag].push(host);
      return groups;
    }, {});
  }, [hosts, groupByTags]);

  // Обработчик обновления тега
  const handleUpdateTag = async (hostId, newTag, tagColor) => {
    try {
      // Обновляем тег в posts
      const hostRef = databaseRef(db, `posts/${hostId}`);
      await update(hostRef, { tag: newTag, tagColor });

      // Обновляем тег в user-posts
      const userPostRef = databaseRef(db, `user-posts/${user.uid}/${hostId}`);
      await update(userPostRef, { tag: newTag, tagColor });
      
      // Обновляем локальное состояние
      setHosts(prevHosts => {
        const updatedHosts = prevHosts.map(host => 
          host.id === hostId 
            ? { ...host, tag: newTag, tagColor }
            : host
        );
        
        // Обновляем кэш
        try {
          cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
          setCachedHosts(prevCache => ({
            ...prevCache,
            [hostId]: { ...prevCache[hostId], tag: newTag, tagColor }
          }));
        } catch (cacheError) {
          console.error('Cache update error:', cacheError);
          toast.error(t('errors.cache.write'));
        }
        
        return updatedHosts;
      });

      toast.success(t('notifications.tagUpdated'));
    } catch (error) {
      console.error('Error updating tag:', error);
      toast.error(t('notifications.hosts.updateError'));
    }
  };

  const handleUpdateNotes = async (hostId, newNotes) => {
    try {
      // Обновляем заметки в posts
      const hostRef = databaseRef(db, `posts/${hostId}`);
      await update(hostRef, { notes: newNotes });

      // Обновляем заметки в user-posts
      const userPostRef = databaseRef(db, `user-posts/${user.uid}/${hostId}`);
      await update(userPostRef, { notes: newNotes });
      
      // Обновляем локальное состояние
      setHosts(prevHosts => {
        const updatedHosts = prevHosts.map(host => 
          host.id === hostId 
            ? { ...host, notes: newNotes }
            : host
        );
        
        // Обновляем кэш
        try {
          cacheManager.setCachedData(`hosts_${user.uid}`, updatedHosts);
          setCachedHosts(prevCache => ({
            ...prevCache,
            [hostId]: { ...prevCache[hostId], notes: newNotes }
          }));
        } catch (cacheError) {
          console.error('Cache update error:', cacheError);
          toast.error(t('errors.cache.write'));
        }
        
        return updatedHosts;
      });

      toast.success(t('notifications.notesUpdated'));
    } catch (error) {
      console.error('Error updating notes:', error);
      toast.error(t('notifications.hosts.updateError'));
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(t('notifications.copied'));
    } catch (err) {
      toast.error(t('notifications.copyError'));
    }
  };

  const sortedHosts = useMemo(() => {
    return Object.values(cachedHosts)
      .sort((a, b) => {
        const dateA = a.lastUpdate ? new Date(a.lastUpdate).getTime() : 0;
        const dateB = b.lastUpdate ? new Date(b.lastUpdate).getTime() : 0;
        return dateB - dateA;
      });
  }, [cachedHosts]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          Error loading hosts: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="container mx-auto px-4 h-full">
        {hosts.length > 0 && (
          <div className="mb-6 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setGroupByTags(!groupByTags)}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                  groupByTags ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-700'
                }`}
              >
                <ViewColumnsIcon className="w-5 h-5" />
                <span>{t('hosts.groupByTags')}</span>
              </button>
              <div className="flex items-center gap-2 bg-gray-100 rounded-lg p-1">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`flex items-center gap-2 px-3 py-1 rounded-md ${
                    viewMode === 'grid' ? 'bg-white text-blue-500 shadow-sm' : 'text-gray-700'
                  }`}
                >
                  <Squares2X2Icon className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setViewMode('table')}
                  className={`flex items-center gap-2 px-3 py-1 rounded-md ${
                    viewMode === 'table' ? 'bg-white text-blue-500 shadow-sm' : 'text-gray-700'
                  }`}
                >
                  <TableCellsIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
            <button
              onClick={saveViewSettings}
              className="text-sm text-blue-500 hover:text-blue-600"
            >
              {t('hosts.saveView')}
            </button>
          </div>
        )}

        <div className={`h-full ${hosts.length === 0 ? 'flex items-center justify-center' : 'py-8'}`}>
          {hosts.length === 0 ? (
            <EmptyState onAddClick={() => setShowAddModal(true)} />
          ) : viewMode === 'table' ? (
            <div className="overflow-x-auto">
              {groupByTags ? (
                <div className="space-y-8">
                  {Object.entries(groupedHosts).map(([tag, hosts]) => (
                    <div key={tag} className="space-y-4">
                      <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                        <TagIcon className="w-5 h-5 text-gray-400" />
                        {tag === 'ungrouped' ? t('hosts.ungrouped') : tag}
                      </h3>
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {t('name')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {t('ip')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {t('tag')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {t('notes')}
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {t('lastUpdate')}
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">{t('actions')}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {hosts.map((host) => (
                            <tr key={host.id} className="hover:bg-gray-50">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {host.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div className="flex items-center gap-2">
                                  <span>{host.ip}</span>
                                  <button
                                    onClick={() => copyToClipboard(host.ip)}
                                    className="text-gray-400 hover:text-gray-600"
                                  >
                                    <ClipboardIcon className="w-4 h-4" />
                                  </button>
                                  <button
                                    onClick={() => handleUpdateClick(host.id)}
                                    className="text-gray-400 hover:text-gray-600"
                                  >
                                    <PencilIcon className="w-4 h-4" />
                                  </button>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <div className="flex items-center gap-2">
                                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white ${
                                    TAG_COLORS.find(color => color.id === host.tagColor)?.bg || TAG_COLORS[0].bg
                                  }`}>
                                    {host.tag || t('hosts.card.noTag')}
                                  </span>
                                  <button
                                    onClick={() => handleUpdateTagClick(host.id)}
                                    className="text-gray-400 hover:text-gray-600"
                                  >
                                    <PencilIcon className="w-4 h-4" />
                                  </button>
                                </div>
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500 max-w-xs">
                                <div className="flex items-center gap-2">
                                  <span className="truncate">{host.notes}</span>
                                  <button
                                    onClick={() => handleUpdateNotesClick(host.id)}
                                    className="text-gray-400 hover:text-gray-600 flex-shrink-0"
                                  >
                                    <PencilIcon className="w-4 h-4" />
                                  </button>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {formatDate(host.lastUpdate)}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button
                                  onClick={() => handleDeleteHost(host.id)}
                                  className="text-gray-400 hover:text-red-600"
                                  title={t('tooltips.hosts.delete')}
                                >
                                  {deletingHostIds.has(host.id) ? (
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-red-500" />
                                  ) : (
                                    <TrashIcon className="w-5 h-5" />
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('name')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('ip')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('tag')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('notes')}
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {t('lastUpdate')}
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">{t('actions')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedHosts.map((host) => (
                      <tr key={host.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {host.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="flex items-center gap-2">
                            <span>{host.ip}</span>
                            <button
                              onClick={() => copyToClipboard(host.ip)}
                              className="text-gray-400 hover:text-gray-600"
                            >
                              <ClipboardIcon className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleUpdateClick(host.id)}
                              className="text-gray-400 hover:text-gray-600"
                            >
                              <PencilIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="flex items-center gap-2">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white ${
                              TAG_COLORS.find(color => color.id === host.tagColor)?.bg || TAG_COLORS[0].bg
                            }`}>
                              {host.tag || t('hosts.card.noTag')}
                            </span>
                            <button
                              onClick={() => handleUpdateTagClick(host.id)}
                              className="text-gray-400 hover:text-gray-600"
                            >
                              <PencilIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500 max-w-xs">
                          <div className="flex items-center gap-2">
                            <span className="truncate">{host.notes}</span>
                            <button
                              onClick={() => handleUpdateNotesClick(host.id)}
                              className="text-gray-400 hover:text-gray-600 flex-shrink-0"
                            >
                              <PencilIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(host.lastUpdate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => handleDeleteHost(host.id)}
                            className="text-gray-400 hover:text-red-600"
                            title={t('tooltips.hosts.delete')}
                          >
                            {deletingHostIds.has(host.id) ? (
                              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-red-500" />
                            ) : (
                              <TrashIcon className="w-5 h-5" />
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          ) : groupByTags ? (
            <div className="space-y-8">
              {Object.entries(groupedHosts).map(([tag, hosts]) => (
                <div key={tag} className="space-y-4">
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                    <TagIcon className="w-5 h-5 text-gray-400" />
                    {tag === 'ungrouped' ? t('hosts.ungrouped') : tag}
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {hosts.map((host) => (
                      <div 
                        key={host.id}
                        className="transform hover:-translate-y-1.5 transition-all duration-300"
                      >
                        <HostCard
                          host={host}
                          onDeleteHost={handleDeleteHost}
                          onUpdate={onUpdateHost}
                          onUpdateTag={handleUpdateTag}
                          onUpdateNotes={handleUpdateNotes}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {sortedHosts.map((host) => (
                <div 
                  key={host.id}
                  className="transform hover:-translate-y-1.5 transition-all duration-300"
                >
                  <HostCard
                    host={host}
                    onDeleteHost={handleDeleteHost}
                    onUpdate={onUpdateHost}
                    onUpdateTag={handleUpdateTag}
                    onUpdateNotes={handleUpdateNotes}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {showAddModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl shadow-2xl w-full max-w-lg mx-auto">
            <AddHostModal
              onClose={() => setShowAddModal(false)}
              onAdd={handleAdd}
            />
          </div>
        </div>
      )}

      {editingHostId && (
        <EditIpModal
          hostId={editingHostId}
          currentIp={hosts.find(h => h.id === editingHostId)?.ip}
          onClose={() => setEditingHostId(null)}
          onUpdate={handleUpdateComplete}
        />
      )}

      {editingTagHostId && (
        <EditTagModal
          hostId={editingTagHostId}
          currentTag={hosts.find(h => h.id === editingTagHostId)?.tag}
          currentColor={hosts.find(h => h.id === editingTagHostId)?.tagColor}
          onClose={() => setEditingTagHostId(null)}
          onUpdate={handleUpdateTag}
        />
      )}

      {editingNotesHostId && (
        <EditNotesModal
          hostId={editingNotesHostId}
          currentNotes={hosts.find(h => h.id === editingNotesHostId)?.notes}
          onClose={() => setEditingNotesHostId(null)}
          onUpdate={handleUpdateNotes}
        />
      )}
    </div>
  );
}); 
import { Link } from 'react-router-dom';
import { 
  QuestionMarkCircleIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline';
import { useLocale } from '../contexts/LocaleContext';
import { GIT_COMMIT_HASH } from '../constants/git-info';

export const Footer = () => {
  const { t } = useLocale();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
          {/* О компании */}
          <div className="space-y-3">
            <div className="flex items-center gap-2 mb-3">
              <img 
                src="/unicorn-logo.svg" 
                alt={t('common.brandName')} 
                className="h-5 w-5 transform hover:scale-110 transition-transform duration-300" 
              />
              <span className="font-bold text-gray-100">{t('common.brandName')}</span>
            </div>
            <p className="text-sm text-gray-400">
              {t('footer.description')}
            </p>
          </div>

          {/* Продукт */}
          <div>
            <h3 className="font-semibold text-gray-100 mb-3">{t('footer.product.title')}</h3>
            <ul className="space-y-1.5">
              <li>
                <Link to="/features" className="text-sm text-gray-400 hover:text-white">
                  {t('footer.product.features')}
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-sm text-gray-400 hover:text-white">
                  {t('footer.product.pricing')}
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-sm text-gray-400 hover:text-white">
                  {t('footer.product.blog')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Поддержка */}
          <div>
            <h3 className="font-semibold text-gray-100 mb-3">{t('footer.support.title')}</h3>
            <ul className="space-y-1.5">
              <li>
                <Link to="/help" className="text-sm text-gray-400 hover:text-white flex items-center gap-1">
                  <QuestionMarkCircleIcon className="w-4 h-4" />
                  <span>{t('footer.support.helpCenter')}</span>
                </Link>
              </li>
              <li>
                <a 
                  href="mailto:hey@dynip.space" 
                  className="text-sm text-gray-400 hover:text-white flex items-center gap-1"
                >
                  <EnvelopeIcon className="w-4 h-4" />
                  <span>{t('footer.support.contact')}</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Компания */}
          <div>
            <h3 className="font-semibold text-gray-100 mb-3">{t('footer.company.title')}</h3>
            <ul className="space-y-1.5">
              <li>
                <Link to="/about" className="text-sm text-gray-400 hover:text-white">
                  {t('footer.company.about')}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-sm text-gray-400 hover:text-white">
                  {t('footer.company.terms')}
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-sm text-gray-400 hover:text-white">
                  {t('footer.company.privacy')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Разделитель и копирайт */}
        <div className="border-t border-gray-800 pt-4">
          <div className="flex flex-col items-center md:items-start gap-1.5">
            <div className="text-sm text-gray-400 flex items-center justify-center md:justify-start gap-1">
              {t('common.madeWith')} <span className="text-red-500">❤</span> Alexander Bykov, 2017—{currentYear}
            </div>
            <div className="text-xs text-gray-500">
              Build #{GIT_COMMIT_HASH || 'dev'}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}; 
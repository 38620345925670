import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocale } from '../contexts/LocaleContext';
import { useAuth } from '../contexts/AuthContext';
import { useNotifications } from '../services/notifications';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { TAG_COLORS } from '../constants/colors';
import { formatDate } from '../utils/date';
import { 
  ClipboardIcon, 
  TrashIcon, 
  PencilIcon,
  GlobeAltIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CheckIcon,
  XMarkIcon,
  TagIcon,
  DocumentTextIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import debug from '../utils/debug';

export const HostCard = ({ host, onDeleteHost, onUpdate, onUpdateTag, onUpdateNotes }) => {
  const { t } = useLocale();
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [newIp, setNewIp] = useState(host.ip);
  const [currentIp, setCurrentIp] = useState(host.ip);
  const [ipError, setIpError] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditingTag, setIsEditingTag] = useState(false);
  const [newTag, setNewTag] = useState(host.tag || '');
  const [tagColor, setTagColor] = useState(host.tagColor || TAG_COLORS[0].id);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(host.notes || '');
  const [isExpanded, setIsExpanded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { success, error, info } = useNotifications();

  useEffect(() => {
    setCurrentIp(host.ip);
    setNewIp(host.ip);
  }, [host.ip]);

  // Эффект для управления скроллом при открытии/закрытии модального окна
  useEffect(() => {
    if (isExpanded) {
      setScrollPosition(window.pageYOffset);
    }
  }, [isExpanded]);

  const validateIpv4 = (value) => {
    if (!value) return 'IP адрес обязателен';

    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (!ipv4Regex.test(value)) {
      return 'Неверный формат IP адреса';
    }

    const octets = value.split('.');
    const isValid = octets.every(octet => {
      const num = parseInt(octet, 10);
      return num >= 0 && num <= 255;
    });

    if (!isValid) {
      return 'Каждое число должно быть от 0 до 255';
    }

    return '';
  };

  const handleCopyIp = async () => {
    try {
      await navigator.clipboard.writeText(host.ip);
      setIsCopied(true);
      // console.log('Translation key value:', t('notifications.ipCopied'));
      // success(t('notifications.ipCopied'));
      toast.success('IP address copied');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      error('errors.copyFailed');
    }
  };

  const handleIpChange = (e) => {
    const value = e.target.value;
    setNewIp(value);
    setIpError(validateIpv4(value));
  };

  const handleSubmitIp = async (e) => {
    e.preventDefault();
    const validationError = validateIpv4(newIp);
    if (validationError) {
      error('errors.invalidIp');
      return;
    }

    setIsUpdating(true);

    try {
      const response = await fetch('/updatehost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hostname: host.name,
          ip: newIp,
          uid: user.uid
        })
      });

      if (!response.ok) {
        throw new Error(await response.text() || 'Failed to update IP');
      }

      setCurrentIp(newIp);
      await onUpdate(host.id, newIp);
      setIsEditing(false);
    } catch (err) {
      error('errors.updateFailed');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await fetch('/delhost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
        body: JSON.stringify({
          hostname: host.name,
          uid: user.uid
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete host');
      }
      
      await onDeleteHost(host.id);
      // success('notifications.hostDeleted');
    } catch (err) {
      error('errors.deleteFailed');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleTagSubmit = async (e) => {
    e.preventDefault();
    try {
      await onUpdateTag(host.id, newTag, tagColor);
      setIsEditingTag(false);
    } catch (err) {
      error('errors.updateFailed');
    }
  };

  const handleNotesSubmit = async () => {
    try {
      const response = await fetch('/updatenotes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hostname: host.name,
          uid: user.uid,
          notes: notes
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update notes');
      }

      await onUpdateNotes(host.id, notes);
      setIsEditingNotes(false);
      setIsExpanded(false);
    } catch (err) {
      error('errors.updateFailed');
    }
  };

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden border border-gray-100 relative">
      {isDeleting && (
        <div className="absolute inset-0 bg-white/50 backdrop-blur-sm flex items-center justify-center z-10">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500" />
        </div>
      )}
      
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-start gap-2">
          <div className="space-y-1.5 min-w-0">
            <div className="flex items-center gap-2.5 min-w-0">
              <ComputerDesktopIcon className="w-5 h-5 text-blue-500 flex-shrink-0" />
              <div className="min-w-0 overflow-hidden">
                <h3 className="text-lg font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent truncate">
                  {host.name}
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    .dynip.space
                  </span>
                </h3>
              </div>
            </div>
          </div>
          <button
            onClick={handleDelete}
            disabled={isDeleting}
            className="text-gray-400 hover:text-red-500 transition-colors p-1 flex-shrink-0"
            title={t('tooltips.hosts.delete')}
          >
            {isDeleting ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-red-500" />
            ) : (
              <TrashIcon className="w-5 h-5" />
            )}
          </button>
        </div>

        <div className="space-y-3">
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
            <GlobeAltIcon className="w-4 h-4" />
            <span>{t('hosts.card.ipAddress')}</span>
          </div>
          
          {isEditing ? (
            <form onSubmit={handleSubmitIp} className="flex items-center gap-2">
              <input
                type="text"
                value={newIp}
                onChange={handleIpChange}
                className={`flex-1 px-3 py-1.5 border rounded-lg text-sm ${
                  ipError ? 'border-red-300' : 'border-gray-300'
                }`}
                disabled={isUpdating}
              />
              <div className="flex items-center gap-1">
                <button
                  type="submit"
                  className="p-1.5 text-green-600 hover:text-green-700 disabled:opacity-50"
                  disabled={!!ipError || isUpdating}
                  title={t('tooltips.hosts.save')}
                >
                  <CheckIcon className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditing(false);
                    setIpError('');
                  }}
                  className="p-1.5 text-gray-400 hover:text-gray-500"
                  disabled={isUpdating}
                  title={t('tooltips.hosts.cancel')}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>
            </form>
          ) : (
            <div className="flex items-center gap-3">
              <div 
                className="flex items-center gap-2 cursor-pointer group"
                onClick={() => setIsEditing(true)}
                title={t('tooltips.hosts.edit')}
              >
                <span className="text-gray-900 font-medium break-all">
                  {currentIp}
                </span>
                <PencilIcon className="w-4 h-4 text-gray-400 group-hover:text-blue-500" />
              </div>
              <button
                onClick={handleCopyIp}
                className="text-gray-400 hover:text-blue-500 transition-colors p-1"
                title={isCopied ? t('tooltips.hosts.copied') : t('tooltips.hosts.copyIp')}
              >
                {isCopied ? <CheckIcon className="w-4 h-4" /> : <ClipboardIcon className="w-4 h-4" />}
              </button>
            </div>
          )}
        </div>

        <div className="flex items-center gap-2 text-sm text-gray-500 pt-2">
          <ClockIcon className="w-4 h-4" />
          <span>{t('hosts.card.lastUpdate')}</span>
          <time 
            dateTime={host.lastUpdate} 
            className="text-gray-900 font-medium"
          >
            {formatDate(host.lastUpdate)}
          </time>
        </div>

        <div className="space-y-3">
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
            <TagIcon className="w-4 h-4" />
            <span>{t('hosts.card.tag')}</span>
          </div>
          
          {isEditingTag ? (
            <form onSubmit={handleTagSubmit} className="space-y-3">
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                className="flex-1 px-3 py-1.5 border rounded-lg text-sm border-gray-300"
                placeholder={t('hosts.card.tagPlaceholder')}
              />
              <div className="flex gap-2 items-center">
                {TAG_COLORS.map(color => (
                  <button
                    key={color.id}
                    type="button"
                    onClick={() => setTagColor(color.id)}
                    className={`w-6 h-6 rounded-full ${color.bg} ${
                      tagColor === color.id ? 'ring-2 ring-offset-2 ring-gray-400' : ''
                    }`}
                    title={color.label}
                  />
                ))}
              </div>
              <div className="flex items-center gap-1">
                <button
                  type="submit"
                  className="p-1.5 text-green-600 hover:text-green-700"
                  title={t('tooltips.hosts.save')}
                >
                  <CheckIcon className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsEditingTag(false);
                    setNewTag(host.tag || '');
                    setTagColor(host.tagColor || TAG_COLORS[0].id);
                  }}
                  className="p-1.5 text-gray-400 hover:text-gray-500"
                  title={t('tooltips.hosts.cancel')}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>
            </form>
          ) : (
            <div 
              className="flex items-center gap-2 cursor-pointer group"
              onClick={() => setIsEditingTag(true)}
            >
              {host.tag ? (
                <span className={`text-white px-2 py-0.5 rounded-full text-sm ${
                  TAG_COLORS.find(c => c.id === host.tagColor)?.bg || TAG_COLORS[0].bg
                }`}>
                  {host.tag}
                </span>
              ) : (
                <span className="text-gray-900 font-medium">
                  {t('hosts.card.noTag')}
                </span>
              )}
              <PencilIcon className="w-4 h-4 text-gray-400 group-hover:text-blue-500" />
            </div>
          )}
        </div>

        <div className="space-y-3">
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
            <DocumentTextIcon className="w-4 h-4" />
            <span>{t('hosts.card.notes')}</span>
          </div>
          
          {notes ? (
            <div 
              className="mt-3 cursor-pointer group"
              onClick={() => setIsExpanded(true)}
            >
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <DocumentTextIcon className="w-4 h-4" />
                <p className="truncate">
                  {notes}
                </p>
              </div>
            </div>
          ) : (
            <button
              onClick={() => {
                setNotes('');
                setIsEditingNotes(true);
                setIsExpanded(true);
              }}
              className="text-sm text-blue-500 hover:text-blue-600 flex items-center gap-1"
            >
              <PlusIcon className="w-4 h-4" />
              {t('hosts.card.addNotes')}
            </button>
          )}
        </div>

        {isExpanded && createPortal(
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div 
              className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm"
              onClick={() => setIsExpanded(false)}
            />
            <div 
              className="relative w-full max-w-lg p-6 bg-white rounded-2xl shadow-2xl transform transition-all mx-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-lg font-semibold">{host.name}</h3>
                <button
                  onClick={() => setIsExpanded(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              </div>
              
              {isEditingNotes ? (
                <div className="space-y-4">
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="w-full h-32 px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder={t('hosts.card.notesPlaceholder')}
                  />
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => setIsEditingNotes(false)}
                      className="px-4 py-2 text-gray-600 hover:text-gray-700"
                    >
                      {t('tooltips.hosts.cancel')}
                    </button>
                    <button
                      onClick={handleNotesSubmit}
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                      {t('tooltips.hosts.save')}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                  <p className="text-gray-600 whitespace-pre-wrap">
                    {notes || t('hosts.card.noNotes')}
                  </p>
                  <div className="flex justify-end">
                    <button
                      onClick={() => setIsEditingNotes(true)}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      {t('hosts.card.editNotes')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>,
          document.body
        )}
      </div>

      {isUpdating && (
        <div className="absolute inset-0 bg-white/50 backdrop-blur-sm flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}
    </div>
  );
};

export default {
  common: {
    brandName: 'DynIP Space',
    login: 'Login',
    signup: 'Sign Up',
    startFree: 'Start Free',
    learnMore: 'Learn More',
    getStarted: 'Get Started',
    madeWith: 'Made with',
    by: 'by',
    logout: 'Logout',
    locale: 'en-US',
    back: 'Back'
  },
  nav: {
    hosts: 'Hosts',
    account: 'Account',
    admin: 'Admin Panel',
    features: 'Features',
    pricing: 'Pricing',
    blog: 'Blog',
    help: 'Help',
  },
  footer: {
    description: 'Modern dynamic IP address management service',
    product: {
      title: 'Product',
      features: 'Features',
      pricing: 'Pricing',
      blog: 'Blog',
    },
    support: {
      title: 'Support',
      helpCenter: 'Help Center',
      contact: 'Support',
    },
    company: {
      title: 'Company',
      about: 'About',
      terms: 'Terms of Service',
      privacy: 'Privacy Policy',
    },
  },
  landing: {
    hero: {
      title: 'Manage access to your devices',
      titleHighlight: 'from anywhere',
      description: 'DynIP Space is a modern dynamic DNS service for your infrastructure. A simple, reliable and secure way to access your devices from anywhere in the world.',
    },
    stats: {
      activeUsers: 'Active Users',
      updatesDaily: 'Updates Daily',
      uptime: 'Uptime',
      countries: 'Countries',
    },
    features: {
      title: 'Built for Developers',
      api: {
        title: 'Powerful API',
        description: 'Full control over DNS records via modern REST API',
      },
      automation: {
        title: 'Automation',
        description: 'Ready-made scripts and integrations for popular platforms',
      },
      monitoring: {
        title: 'Monitoring',
        description: 'Detailed statistics and real-time notifications',
      },
    },
    cta: {
      title: 'Ready to Start?',
      description: 'Join thousands of developers already using DynIP Space',
      button: 'Create Account',
    },
  },
  pages: {
    titles: {
      hosts: 'Hosts Management',
      account: 'Account Settings',
      help: 'Help Center',
      admin: 'Admin Panel',
      features: 'Features',
      pricing: 'Pricing',
      blog: 'Blog',
      about: 'About Us',
      terms: 'Terms of Service',
      privacy: 'Privacy Policy',
    },
    actions: {
      addHost: 'Add Host',
    },
  },
  features: {
    hero: {
      title: 'DynIP Space Features',
      description: 'Modern dynamic DNS service for your infrastructure. A simple, reliable, and secure way to access your devices.'
    },
    cards: [
      {
        title: 'Automatic DNS Updates',
        description: 'Instant DNS record updates when your device\'s IP address changes. Support for both IPv4 and IPv6.'
      },
      {
        title: 'Secure API',
        description: 'SSL/TLS secured API for updates. Support for authorization tokens and rate limiting.',
      },
      {
        title: 'Real-time Monitoring',
        description: 'Track the status of your hosts and receive notifications about IP address changes.',
      },
      {
        title: 'Flexible Configuration',
        description: 'Configure TTL, subdomains, and integrate with popular DNS providers.',
      },
      {
        title: 'Fast Synchronization',
        description: 'Minimal propagation time thanks to optimized infrastructure.',
      },
      {
        title: 'Integrations & API',
        description: 'Ready-made clients for popular platforms and detailed API documentation.',
      },
      {
        title: 'Global Infrastructure',
        description: 'Distributed server network ensures high availability and quick response.',
      },
      {
        title: 'All Devices Support',
        description: 'Works with routers, NAS, servers, and IoT devices from any manufacturer.',
      }
    ],
    pricing: {
      title: 'Ready to Start?',
      description: 'Choose a suitable plan and start using all the features of the service today.',
      button: 'View Pricing'
    },
    cta: {
      title: 'Join Us',
      description: 'Thousands of developers already use DynIP Space to manage their infrastructure',
      button: 'Create Account'
    }
  },
  pricing: {
    hero: {
      title: 'Simple and Clear Pricing',
      description: 'Choose a plan that works for you. All plans include basic features.'
    },
    plans: {
      free: {
        name: 'Free',
        price: '$0',
        period: '/month',
        description: 'Perfect for personal projects and getting started',
        features: [
          'Up to 3 hosts',
          'Basic monitoring',
          'Standard update time',
          'Basic support'
        ],
        button: 'Start Free'
      },
      pro: {
        name: 'Pro',
        price: '$5',
        period: '/month',
        badge: 'Popular',
        description: 'For professionals and businesses with advanced needs',
        features: [
          'Up to 100 hosts',
          'Advanced monitoring',
          'Fast update time',
          'Priority support'
        ],
        button: 'Try Pro'
      }
    },
    features: {
      title: 'All Plans Include',
      cards: {
        updates: {
          title: 'Fast Updates',
          description: 'Instant DNS record updates when IP address changes'
        },
        security: {
          title: 'Security',
          description: 'SSL/TLS encryption and protection against unauthorized access'
        },
        api: {
          title: 'API Access',
          description: 'Full-featured REST API for automation'
        },
        monitoring: {
          title: 'Monitoring',
          description: 'Status tracking and change notifications'
        }
      }
    },
    faq: {
      title: 'Frequently Asked Questions',
      items: [
        {
          question: 'Can I upgrade from Free to Pro plan?',
          answer: 'Yes, you can upgrade to Pro plan at any time. All your settings and hosts will be preserved.'
        },
        {
          question: 'How does billing work?',
          answer: 'We accept Visa, Mastercard, and PayPal payments. Billing occurs automatically each month. You can change your payment method or cancel your subscription anytime in your account settings.'
        },
        {
          question: 'Is there a money-back guarantee?',
          answer: 'Yes, within the first 14 days you can cancel your subscription and get a full refund. No questions asked or complicated procedures — just contact our support.'
        },
        {
          question: 'What IP update methods are supported?',
          answer: 'We support all popular update methods: via web interface, REST API, clients for popular routers and NAS devices, and we provide ready-made scripts for automation.'
        },
        {
          question: 'Are there any update limits?',
          answer: 'The Free plan has a limit of 100 updates per day per host. The Pro plan has no limits, you can update your IP as often as needed.'
        }
      ]
    }
  },
  modals: {
    addHost: {
      title: 'Add New Host',
      name: {
        label: 'Hostname',
        placeholder: 'Enter hostname',
        hint: 'Latin letters, numbers and hyphen only'
      },
      ip: {
        label: 'IP Address',
        placeholder: 'Enter IP address',
        hint: 'Leave empty for automatic detection'
      },
      tag: {
        label: 'Tag',
        placeholder: 'Enter tag',
        hint: 'Tags help organize hosts into groups'
      },
      notes: {
        label: 'Notes',
        placeholder: 'Enter notes about this host',
        hint: 'Optional notes to help you remember details about this host'
      },
      buttons: {
        add: 'Add',
        adding: 'Adding...',
        cancel: 'Cancel'
      },
      hostsCount: 'Using {{current}} of {{limit}} available hosts'
    }
  },
  notifications: {
    hosts: {
      added: 'Host successfully added',
      deleted: 'Host successfully deleted',
      updated: 'IP address successfully updated',
      limitReached: 'Host limit reached for your plan'
    },
    account: {
      tokenUpdated: 'API token successfully updated'
    },
    ipCopied: 'IP address copied',
    ipUpdated: 'IP address successfully updated',
    hostDeleted: 'Host successfully deleted 22222',
    hostsUpdated: 'Hosts list updated',
    notesUpdated: 'Notes successfully updated'
  },
  validation: {
    hostname: {
      required: 'Hostname is required',
      tooShort: 'Minimum length is 3 characters',
      tooLong: 'Maximum length is 63 characters',
      invalid: 'Use only latin letters, numbers and hyphen. Hyphen cannot be at the start or end',
    },
    ip: {
      invalid: 'Invalid IP address format',
      range: 'Each number must be between 0 and 255',
    },
  },
  hosts: {
    card: {
      ipAddress: 'IP Address',
      lastUpdate: 'Last Update',
      actions: {
        update: 'Update',
        delete: 'Delete'
      },
      tag: 'Tag',
      tagPlaceholder: 'Enter tag',
      noTag: 'No tag',
      notes: 'Notes',
      notesPlaceholder: 'Enter notes about this host...',
      editNotes: 'Edit notes',
      noNotes: 'No notes added yet',
      addNotes: 'Add notes'
    },
    errors: {
      limitReached: 'You have reached the maximum number of hosts for your plan. Please upgrade to add more hosts.',
      addHost: {
        hostExists: 'This hostname is already taken',
        invalidHostname: 'Invalid hostname format',
        invalidIp: 'Invalid IP address format',
        limitReached: 'You have reached the maximum number of hosts for your plan',
        invalidToken: 'Invalid authentication token',
        tokenExpired: 'Authentication token has expired',
        userNotFound: 'User not found',
        userBlocked: 'Your account has been blocked',
        default: 'Failed to add host'
      }
    },
    addHost: {
      hostsCount: 'Using {{current}} of {{limit}} available hosts'
    },
    empty: {
      title: 'No hosts yet',
      description: 'Get started by adding your first host. Click the button below to create a new host.',
      addButton: 'Add First Host'
    },
    confirmDelete: 'Are you sure you want to delete this host?',
    deleting: 'Deleting...',
    groupByTags: 'Group by tags',
    saveView: 'Save view',
    ungrouped: 'Untagged'
  },
  tooltips: {
    hosts: {
      copyIp: 'Copy IP',
      copied: 'Copied!',
      delete: 'Delete host',
      edit: 'Edit IP',
      cancel: 'Cancel',
      save: 'Save changes'
    },
    account: {
      copyToken: 'Copy token',
      revokeToken: 'Revoke token',
      copied: 'Copied!',
      showToken: 'Show token',
      hideToken: 'Hide token',
      revokeTokenConfirm: 'Are you sure? All existing integrations will stop working.',
      cancel: 'Cancel',
      confirmRevoke: 'Yes, revoke token'
    },
    common: {
      close: 'Close',
      menu: 'Open menu'
    }
  },
  account: {
    sections: {
      profile: {
        title: 'Profile',
        description: 'Manage your profile and account settings',
      },
      plan: {
        title: 'Subscription Plan',
        description: 'Information about your current plan and limits',
        currentPlan: 'Current plan',
        hostsLimit: 'Hosts limit',
        hostsUsed: 'Hosts used',
        upgrade: 'Upgrade plan'
      },
      api: {
        title: 'API Token',
        description: 'Manage your API access token',
        token: 'Your API token',
        warning: 'Never share your token with third parties',
        revokeButton: 'Revoke token'
      }
    }
  },
  admin: {
    sections: {
      stats: {
        title: 'Statistics',
        description: 'Overview of system usage',
        cards: {
          totalPosts: 'Total Posts',
          totalUsers: 'Total Users'
        }
      },
      users: {
        title: 'Users Management',
        description: 'Manage users and their posts',
        table: {
          posts: 'Posts',
          noPosts: 'No posts',
          postsCount: '{count} posts',
          created: 'Created',
          title: 'Title',
          lastUpdate: 'Last Update',
          actions: 'Actions',
          ip: 'IP Address'
        },
        tooltips: {
          deletePost: 'Delete post',
          deletePosts: 'Delete all posts',
          blockUser: 'Block user',
          unblockUser: 'Unblock user'
        },
        actions: {
          block: 'Block',
          unblock: 'Unblock',
          deletePosts: 'Delete all posts'
        },
        showEmpty: 'Show users without hosts'
      }
    }
  },
  about: {
    hero: {
      title: 'About DynIP Space',
      description: 'DynIP Space is a modern dynamic DNS (DDNS) service designed to simplify domain name management in dynamic IP environments. We help developers, system administrators, and enthusiasts ensure stable access to their services and devices.'
    },
    stats: {
      users: {
        value: '50,000+',
        label: 'Active Users'
      },
      uptime: {
        value: '99.9%',
        label: 'Service Uptime'
      },
      countries: {
        value: '150+',
        label: 'Countries'
      }
    },
    features: {
      infrastructure: {
        title: 'Global Infrastructure',
        description: 'Our distributed network of servers ensures fast DNS record updates and minimal response time worldwide.'
      },
      security: {
        title: 'Security',
        description: 'All updates are protected with SSL/TLS, and API access is controlled through secure tokens.'
      },
      integration: {
        title: 'Easy Integration',
        description: 'Support for popular DDNS clients and a simple REST API for easy integration with any device.'
      },
      monitoring: {
        title: 'Monitoring',
        description: 'Detailed statistics and real-time notifications about the status of your hosts.'
      }
    },
    team: {
      title: 'Our Team',
      description: 'DynIP Space was created by a team of experienced developers and system administrators who faced dynamic IP management challenges themselves. We continuously work on improving the service based on user feedback and modern trends in DNS and network technologies.',
      quote: 'Our mission is to make dynamic IP address management simple and reliable for everyone who needs it. We believe in openness, security, and continuous improvement.',
      signature: '— DynIP Space Team'
    },
    cta: {
      title: 'Start Using DynIP Space Today',
      description: 'Join thousands of users who have already experienced the benefits of our service',
      button: 'Create Free Account'
    }
  },
  terms: {
    hero: {
      title: 'Terms of Service',
      lastUpdate: 'Last updated: March 1, 2024'
    },
    sections: {
      general: {
        title: '1. General Terms',
        description: 'Welcome to DynIP Space. By using our service, you agree to these terms of service. Please read them carefully before starting to use the service.'
      },
      service: {
        title: '2. Service Description',
        description: 'DynIP Space provides dynamic DNS (DDNS) services that allow linking domain names with IP addresses. We offer both free and premium subscription plans with various features and limits.'
      },
      usage: {
        title: '3. Service Usage',
        items: [
          'You agree to provide accurate and current information during registration',
          'You are responsible for maintaining the security of your account credentials',
          'The service must not be used for any illegal activities',
          'We reserve the right to modify service functionality'
        ]
      },
      plans: {
        title: '4. Subscription Plans',
        description: 'The service offers the following subscription plans:',
        items: {
          free: {
            title: 'Free Plan',
            features: 'Up to 3 hosts, basic support'
          },
          pro: {
            title: 'Pro Plan',
            features: 'Up to 100 hosts, priority support, advanced statistics'
          }
        }
      },
      changes: {
        title: '5. Changes to Terms',
        description: 'We may update these terms of service. For any significant changes, we will notify you via email or through a notification in the service interface. By continuing to use the service after changes, you accept the updated terms.'
      }
    }
  },
  privacy: {
    hero: {
      title: 'Privacy Policy',
      lastUpdate: 'Last updated: March 1, 2024'
    },
    sections: {
      intro: {
        title: '1. Introduction',
        description: 'At DynIP Space, we take the protection of your personal data seriously. This policy describes what information we collect and how we use it.'
      },
      data: {
        title: '2. Data Collection',
        description: 'We collect the following types of information:',
        items: [
          'Email address for authentication and notifications',
          'IP addresses of hosts for DDNS service operation',
          'Service usage information',
          'Technical data for service improvement'
        ]
      },
      usage: {
        title: '3. Data Usage',
        description: 'We use the collected information for:',
        items: [
          'Providing and improving our services',
          'Sending important service notifications',
          'Ensuring security and preventing abuse',
          'Analyzing and improving service performance'
        ]
      },
      protection: {
        title: '4. Data Protection',
        description: 'We implement modern technical measures to protect your data, including encryption, secure storage, and regular security system updates.'
      },
      cookies: {
        title: '5. Cookies',
        description: 'We use cookies to improve service functionality and provide a personalized experience. You can manage cookie settings in your browser.'
      },
      rights: {
        title: '6. Your Rights',
        description: 'You have the right to access, correct, or delete your data. To exercise these rights, please contact our support team.'
      }
    }
  },
  help: {
    sections: {
      overview: {
        title: 'Dynamic DNS (DDNS)',
        description: 'DynIP Space provides a dynamic DNS service that allows you to link your domain to an IP address, even if it changes. This is especially useful for:',
        items: [
          'Home servers',
          'Network Attached Storage (NAS)',
          'Surveillance cameras',
          'Game servers'
        ],
        howItWorks: {
          title: 'How it works',
          steps: [
            'Register a host in the control panel (e.g., myserver.dynip.space)',
            'Set up the DDNS client on your device',
            'The client will automatically update the IP address when it changes'
          ]
        }
      },
      clients: {
        title: 'Client Setup',
        ddclient: {
          title: 'ddclient',
          config: `# /etc/ddclient.conf
protocol=dyndns2
use=web
server=api.dynip.space
ssl=yes
login=your-hostname
password=your-token
your-hostname.dynip.space`
        },
        synology: {
          title: 'Synology NAS',
          description: [
            '1. Open Control Panel → External Access → DDNS',
            '2. Click Add and fill in the fields:'
          ],
          fields: [
            'Service Provider: Custom',
            'Server: api.dynip.space',
            'Hostname: your-hostname.dynip.space',
            'Username/Email: your-hostname',
            'Password/Key: your-token'
          ]
        }
      },
      customDomain: {
        title: 'Using Custom Domain',
        description: 'You can use DynIP Space with your own domain. Add the following DNS records in your domain settings:',
        example: '# CNAME record\nhome.example.com  CNAME  your-hostname.dynip.space',
        note: {
          title: 'Note:',
          text: 'Make sure your DNS provider supports CNAME records for apex domains if you plan to use the root domain.'
        },
        result: 'After this, your domain home.example.com will automatically update when the IP address of your-hostname.dynip.space changes'
      }
    }
  },
  blog: {
    hero: {
      title: 'Our Blog',
      description: 'News, guides and articles about DDNS and network technologies'
    },
    posts: [
      {
        id: 1,
        title: 'How Dynamic DNS Works: Complete Guide for Beginners',
        slug: 'how-dynamic-dns-works',
        excerpt: 'Detailed explanation of Dynamic DNS principles, benefits, and setup process.',
        author: 'John Smith',
        date: '2024-03-01',
        category: 'Guides',
        readTime: '7 min',
        tags: ['ddns', 'dns', 'tutorial']
      },
      {
        id: 2,
        title: 'DDNS for IoT Devices and Smart Home',
        slug: 'ddns-for-iot-devices',
        excerpt: 'Practical guide on using DDNS for IoT devices and smart home systems.',
        author: 'Mary Johnson',
        date: '2024-02-28',
        category: 'IoT',
        readTime: '5 min',
        tags: ['iot', 'smart home', 'automation']
      },
      {
        id: 3,
        title: 'DDNS Providers Comparison',
        slug: 'ddns-providers-comparison',
        excerpt: 'Detailed analysis and comparison of popular DDNS providers: functionality, pricing, reliability.',
        author: 'Alex Wilson',
        date: '2024-02-25',
        category: 'Reviews',
        readTime: '6 min',
        tags: ['comparison', 'providers', 'review']
      },
      {
        id: 4,
        title: 'Backup DNS Servers: Why and How',
        slug: 'backup-dns-servers',
        excerpt: 'Guide on setting up and maintaining backup DNS servers.',
        author: 'David Brown',
        date: '2024-02-20',
        category: 'Infrastructure',
        readTime: '8 min',
        tags: ['dns', 'redundancy', 'security']
      },
      {
        id: 5,
        title: 'Optimizing DNS Update Time',
        slug: 'dns-update-optimization',
        excerpt: 'Techniques and methods for optimizing DNS record update time.',
        author: 'Michael Davis',
        date: '2024-02-15',
        category: 'Optimization',
        readTime: '6 min',
        tags: ['optimization', 'performance', 'dns']
      },
      {
        id: 6,
        title: 'Security When Using DDNS',
        slug: 'ddns-security-best-practices',
        excerpt: 'Recommendations for ensuring security when using DDNS.',
        author: 'Paul Smith',
        date: '2024-02-10',
        category: 'Security',
        readTime: '6 min',
        tags: ['security', 'protection', 'monitoring']
      },
      {
        id: 7,
        title: 'DDNS for Remote Work',
        slug: 'ddns-for-remote-work',
        excerpt: 'How to organize secure remote access to work resources using DDNS.',
        author: 'Anna Wilson',
        date: '2024-02-05',
        category: 'Guides',
        readTime: '7 min',
        tags: ['remote work', 'vpn', 'security']
      },
      {
        id: 8,
        title: 'DDNS Integration with Docker',
        slug: 'ddns-docker-integration',
        excerpt: 'Practical guide on using DDNS in Docker containers.',
        author: 'Max Thompson',
        date: '2024-01-30',
        category: 'Docker',
        readTime: '8 min',
        tags: ['docker', 'containers', 'automation']
      },
      {
        id: 9,
        title: 'DDNS Monitoring with Grafana',
        slug: 'ddns-monitoring-grafana',
        excerpt: 'Setting up DDNS monitoring system with Grafana visualization.',
        author: 'George Wilson',
        date: '2024-01-25',
        category: 'Monitoring',
        readTime: '9 min',
        tags: ['grafana', 'monitoring', 'visualization']
      },
      {
        id: 10,
        title: 'DDNS in Microservices Architecture',
        slug: 'ddns-microservices',
        excerpt: 'Using DDNS in modern microservices architecture.',
        author: 'Arthur Brown',
        date: '2024-01-20',
        category: 'Architecture',
        readTime: '8 min',
        tags: ['microservices', 'architecture', 'scaling']
      }
    ],
    backToBlog: 'Back to Blog'
  },
  blogContent: {
    'how-dynamic-dns-works': {
      title: 'How Dynamic DNS Works: Complete Guide for Beginners',
      image: {
        url: '/images/blog/dns-scheme.jpg',
        alt: 'Dynamic DNS Operation Scheme',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `

        <h2>What is Dynamic DNS?</h2>
        <p>
          Dynamic DNS (DDNS) is a technology that automatically updates DNS records when an IP address changes. 
          This is especially useful for home users and small businesses where a static IP address might be 
          unavailable or too expensive.
        </p>

        <h2>How DDNS Works</h2>
        <p>
          The DDNS process consists of several key steps:
        </p>
        <ul>
          <li>The DDNS client regularly checks the device's current IP address</li>
          <li>When an IP address change is detected, the client sends an update to the DDNS server</li>
          <li>The DDNS server updates the DNS record, linking the domain name with the new IP address</li>
          <li>The update propagates through DNS servers</li>
        </ul>

        <h2>Benefits of Using DDNS</h2>
        <p>
          Dynamic DNS offers several significant advantages:
        </p>
        <ul>
          <li>Constant access to home services through a single domain name</li>
          <li>Savings on static IP address costs</li>
          <li>Easy setup and automation</li>
          <li>Support by most modern network equipment</li>
        </ul>

        <h2>Setting Up DDNS</h2>
        <p>
          To start using DDNS, you need to:
        </p>
        <ol>
          <li>Choose a DDNS provider (e.g., DynIP Space)</li>
          <li>Register an account and create a host</li>
          <li>Set up the DDNS client on your device</li>
          <li>Verify IP address update functionality</li>
        </ol>

        <h2>Conclusion</h2>
        <p>
          Dynamic DNS is a simple and effective solution for accessing home services 
          with a dynamic IP address. With modern DDNS services like DynIP Space, 
          setting up and using this technology becomes extremely simple and convenient.
        </p>
      `,
      meta: {
        description: 'Detailed guide on Dynamic DNS principles, benefits, and setup.',
        keywords: 'DDNS, DNS, dynamic IP, DDNS setup, home server',
      }
    },

    'ddns-security-best-practices': {
      title: 'Security When Using Dynamic DNS',
      image: {
        url: '/images/blog/security.jpg',
        alt: 'DDNS Security',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Main Security Threats</h2>
        <p>
          When using DDNS, it's important to consider the following security aspects:
        </p>
        <ul>
          <li>Possibility of DNS update interception</li>
          <li>Unauthorized account access</li>
          <li>DDoS attacks on open services</li>
          <li>Internal infrastructure information leakage</li>
        </ul>

        <h2>Security Recommendations</h2>
        <ol>
          <li>Use only secure HTTPS connections for DNS updates</li>
          <li>Apply strong passwords and two-factor authentication</li>
          <li>Regularly update software on all devices</li>
          <li>Restrict service access by IP and ports</li>
        </ol>

        <h2>Monitoring and Audit</h2>
        <p>
          It's important to regularly check:
        </p>
        <ul>
          <li>Service access logs</li>
          <li>DNS update history</li>
          <li>Unauthorized access attempts</li>
          <li>Security systems functionality</li>
        </ul>
      `,
      meta: {
        description: 'Recommendations for ensuring security when using DDNS.',
        keywords: 'DDNS, security, protection, monitoring',
      }
    },

    'ddns-router-setup-guide': {
      title: 'Setting Up DDNS on Popular Router Brands',
      image: {
        url: '/images/blog/router-setup.jpg',
        alt: 'DDNS Router Setup',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Introduction</h2>
        <p>
          Most modern routers have built-in DDNS support. In this article, we'll look at 
          the setup process on the most popular models.
        </p>

        <h2>ASUS</h2>
        <ol>
          <li>Log into your router's web interface</li>
          <li>Go to WAN → DDNS section</li>
          <li>Select "Custom" in the provider field</li>
          <li>Enter the following details:
            <ul>
              <li>Server: api.dynip.space</li>
              <li>Hostname: your-host.dynip.space</li>
              <li>Username: your-host</li>
              <li>Password: your-token</li>
            </ul>
          </li>
          <li>Save the settings</li>
        </ol>

        <h2>TP-Link</h2>
        <ol>
          <li>Open your router's control panel</li>
          <li>Find the Dynamic DNS section</li>
          <li>Select "Custom DDNS"</li>
          <li>Fill in the fields:
            <ul>
              <li>Service Provider: Custom</li>
              <li>Server Address: api.dynip.space</li>
              <li>Domain Name: your-host.dynip.space</li>
              <li>Username: your-host</li>
              <li>Password: your-token</li>
            </ul>
          </li>
          <li>Apply the settings</li>
        </ol>

        <h2>Mikrotik</h2>
        <p>
          For Mikrotik, you'll need to create a script:
        </p>
        <pre>
/system script
add name=ddns-update source={
  :local hostname "your-hostname"
  :local token "your-token"
  /tool fetch url="https://api.dynip.space/update\
    ?hostname=\$hostname&token=\$token" keep-result=no
}
        </pre>

        <h2>Verifying Operation</h2>
        <p>
          After setup, it's recommended to:
        </p>
        <ul>
          <li>Check DDNS status in router interface</li>
          <li>Verify IP address updates correctly</li>
          <li>Configure update error notifications</li>
        </ul>
      `,
      meta: {
        description: 'Step-by-step instructions for configuring DDNS on popular router models.',
        keywords: 'DDNS, router, setup, ASUS, TP-Link, Mikrotik',
      }
    },

    'monitoring-services-with-ddns': {
      title: 'Monitoring Service Availability via DDNS',
      image: {
        url: '/images/blog/monitoring-tools.jpg',
        alt: 'Service Monitoring',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Importance of Monitoring</h2>
        <p>
          When using DDNS, it's crucial to track:
        </p>
        <ul>
          <li>Service availability</li>
          <li>DNS update correctness</li>
          <li>Response time</li>
          <li>IP address changes</li>
        </ul>

        <h2>Monitoring Tools</h2>
        <p>
          Recommended tools:
        </p>
        <ul>
          <li>Built-in DynIP Space monitoring</li>
          <li>Uptime Robot for external monitoring</li>
          <li>Prometheus + Grafana for detailed analytics</li>
          <li>Custom check scripts</li>
        </ul>

        <h2>Setting Up Alerts</h2>
        <p>
          It's important to configure notifications for:
        </p>
        <ul>
          <li>Service unavailability</li>
          <li>DNS update errors</li>
          <li>IP address changes</li>
          <li>Certificate issues</li>
        </ul>

        <h2>Data Analysis</h2>
        <p>
          Regular analysis allows you to:
        </p>
        <ul>
          <li>Identify problem areas</li>
          <li>Optimize settings</li>
          <li>Plan updates</li>
          <li>Improve service reliability</li>
        </ul>
      `,
      meta: {
        description: 'Guide on organizing service monitoring using DDNS.',
        keywords: 'DDNS, monitoring, availability, notifications',
      }
    },

    'automating-dns-updates': {
      title: 'Automating DNS Record Updates',
      image: {
        url: '/images/blog/automation-tools.jpg',
        alt: 'DNS Automation',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Automation Methods</h2>
        <p>
          There are several main approaches to automating DNS updates:
        </p>
        <ul>
          <li>Using ready-made DDNS clients</li>
          <li>Writing custom scripts</li>
          <li>Integration via REST API</li>
          <li>System services and daemons</li>
        </ul>

        <h2>REST API</h2>
        <p>
          DynIP Space provides a simple REST API for updating records:
        </p>
        <pre>
curl -X POST "https://api.dynip.space/update" \
  -H "Authorization: Bearer your-token" \
  -d "hostname=your-host.dynip.space"
        </pre>

        <h2>Bash Script</h2>
        <p>
          Example of a simple IP update script:
        </p>
        <pre>
#!/bin/bash
TOKEN="your-token"
HOST="your-host.dynip.space"
IP=$(curl -s ifconfig.me)

curl -X POST "https://api.dynip.space/update" \
  -H "Authorization: Bearer $TOKEN" \
  -d "hostname=$HOST&ip=$IP"
        </pre>

        <h2>Python Script</h2>
        <p>
          A more advanced version in Python:
        </p>
        <pre>
import requests
import time

TOKEN = "your-token"
HOST = "your-host.dynip.space"

def get_current_ip():
    return requests.get('https://ifconfig.me').text

def update_dns(ip):
    response = requests.post(
        'https://api.dynip.space/update',
        headers={'Authorization': f'Bearer {TOKEN}'},
        data={'hostname': HOST, 'ip': ip}
    )
    return response.status_code == 200

while True:
    try:
        ip = get_current_ip()
        if update_dns(ip):
            print(f"IP updated: {ip}")
    except Exception as e:
        print(f"Error: {e}")
    time.sleep(300)  # check every 5 minutes
        </pre>

        <h2>System Service</h2>
        <p>
          For automatic startup, you can create a system service:
        </p>
        <pre>
[Unit]
Description=DynIP Space DNS Updater
After=network.target

[Service]
ExecStart=/usr/local/bin/ddns-update.py
Restart=always
User=nobody

[Install]
WantedBy=multi-user.target
        </pre>
      `,
      meta: {
        description: 'Guide on automating DNS record updates with code examples.',
        keywords: 'DDNS, automation, API, scripts, Python, Bash',
      }
    },

    'ddns-for-iot-devices': {
      title: 'DDNS for IoT Devices',
      image: {
        url: '/images/blog/iot-devices.jpg',
        alt: 'IoT Devices',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Why DDNS for IoT?</h2>
        <p>
          DDNS is particularly important for IoT devices for several reasons:
        </p>
        <ul>
          <li>Remote device access</li>
          <li>Centralized management</li>
          <li>Data collection and monitoring</li>
          <li>Cloud service integration</li>
        </ul>

        <h2>Common Use Cases</h2>
        <p>
          Most common applications of DDNS in IoT:
        </p>
        <ul>
          <li>Smart surveillance cameras</li>
          <li>Smart home systems</li>
          <li>Sensors and detectors</li>
          <li>Automation and control</li>
        </ul>

        <h2>Setting Up IoT Devices</h2>
        <p>
          Main setup steps:
        </p>
        <ol>
          <li>Creating a host in DynIP Space</li>
          <li>Configuring the client on the device</li>
          <li>Testing connectivity</li>
          <li>Setting up security</li>
        </ol>

        <h2>Security</h2>
        <p>
          Important security aspects:
        </p>
        <ul>
          <li>Using HTTPS</li>
          <li>Strong passwords</li>
          <li>Regular updates</li>
          <li>Access monitoring</li>
        </ul>

        <h2>Platform Integration</h2>
        <p>
          DDNS can be integrated with popular IoT platforms:
        </p>
        <ul>
          <li>Home Assistant</li>
          <li>OpenHAB</li>
          <li>ThingsBoard</li>
          <li>Node-RED</li>
        </ul>
      `,
      meta: {
        description: 'Practical guide on using DDNS for IoT devices and smart home.',
        keywords: 'DDNS, IoT, smart home, automation, security',
      }
    },

    'backup-dns-servers': {
      title: 'Backup DNS Servers: Why and How',
      image: {
        url: '/images/blog/backup-dns.jpg',
        alt: 'Backup DNS Servers',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Importance of Redundancy</h2>
        <p>
          Backup DNS servers are necessary for:
        </p>
        <ul>
          <li>Ensuring high availability</li>
          <li>Load distribution</li>
          <li>Reducing response time</li>
          <li>DDoS attack protection</li>
        </ul>

        <h2>Solution Architecture</h2>
        <p>
          Typical architecture includes:
        </p>
        <ul>
          <li>Primary DNS server</li>
          <li>Multiple secondary servers</li>
          <li>Data replication mechanism</li>
          <li>Status monitoring</li>
        </ul>

        <h2>Setting Up Replication</h2>
        <p>
          Main setup steps:
        </p>
        <ol>
          <li>DNS server installation (e.g., BIND)</li>
          <li>Zone and record configuration</li>
          <li>Replication setup</li>
          <li>Failover testing</li>
        </ol>

        <h2>Monitoring and Maintenance</h2>
        <p>
          Regular checks needed for:
        </p>
        <ul>
          <li>Synchronization status</li>
          <li>Server response time</li>
          <li>Record correctness</li>
          <li>Error logs</li>
        </ul>

        <h2>Security Recommendations</h2>
        <p>
          Important security aspects:
        </p>
        <ul>
          <li>Zone transfer restrictions</li>
          <li>DNSSEC implementation</li>
          <li>Regular software updates</li>
          <li>Access monitoring</li>
        </ul>
      `,
      meta: {
        description: 'Guide on setting up and maintaining backup DNS servers.',
        keywords: 'DNS, redundancy, high availability, replication',
      }
    },

    'dns-update-optimization': {
      title: 'Optimizing DNS Update Time',
      image: {
        url: '/images/blog/dns-optimization.jpg',
        alt: 'DNS Optimization',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Factors Affecting Speed</h2>
        <p>
          DNS update time is affected by the following factors:
        </p>
        <ul>
          <li>DNS record TTL</li>
          <li>DNS server location</li>
          <li>Caching settings</li>
          <li>Client configuration</li>
        </ul>

        <h2>TTL Optimization</h2>
        <p>
          Recommendations for TTL settings:
        </p>
        <ul>
          <li>For frequently changing IPs: 60-300 seconds</li>
          <li>For stable addresses: 3600-7200 seconds</li>
          <li>Consider DNS server load</li>
          <li>Balance between speed and caching</li>
        </ul>

        <h2>Client Settings</h2>
        <p>
          Client-side optimization:
        </p>
        <ul>
          <li>IP check interval</li>
          <li>Change detection mechanism</li>
          <li>Error handling and retries</li>
          <li>Parallel updates</li>
        </ul>

        <h2>Server Optimization</h2>
        <p>
          Server-side settings:
        </p>
        <ul>
          <li>Geographic distribution</li>
          <li>Load balancing</li>
          <li>Database optimization</li>
          <li>Request caching</li>
        </ul>

        <h2>Performance Monitoring</h2>
        <p>
          Important metrics to track:
        </p>
        <ul>
          <li>Record update time</li>
          <li>Propagation delay</li>
          <li>Error count</li>
          <li>API response time</li>
        </ul>
      `,
      meta: {
        description: 'Techniques and methods for optimizing DNS record update time.',
        keywords: 'DNS, optimization, TTL, performance',
      }
    },

    'ddns-providers-comparison': {
      title: 'DDNS Providers Comparison',
      image: {
        url: '/images/blog/providers-comparison.jpg',
        alt: 'DDNS Providers Comparison',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Comparison Criteria</h2>
        <p>
          When choosing a DDNS provider, consider the following factors:
        </p>
        <ul>
          <li>Service reliability and uptime</li>
          <li>DNS update speed</li>
          <li>Protocol support</li>
          <li>Pricing and plans</li>
          <li>Technical support quality</li>
        </ul>

        <h2>Major Providers</h2>
        <h3>DynIP Space</h3>
        <ul>
          <li>Advantages:
            <ul>
              <li>Modern REST API</li>
              <li>Fast DNS updates</li>
              <li>Detailed documentation</li>
              <li>Built-in monitoring</li>
            </ul>
          </li>
          <li>Disadvantages:
            <ul>
              <li>New service in the market</li>
              <li>Fewer additional features</li>
            </ul>
          </li>
        </ul>

        <h3>No-IP</h3>
        <ul>
          <li>Advantages:
            <ul>
              <li>Long market presence</li>
              <li>Wide device support</li>
            </ul>
          </li>
          <li>Disadvantages:
            <ul>
              <li>High prices</li>
              <li>Outdated interface</li>
            </ul>
          </li>
        </ul>

        <h3>DuckDNS</h3>
        <ul>
          <li>Advantages:
            <ul>
              <li>Completely free</li>
              <li>Easy to use</li>
            </ul>
          </li>
          <li>Disadvantages:
            <ul>
              <li>Limited functionality</li>
              <li>No premium support</li>
            </ul>
          </li>
        </ul>

        <h2>Price Comparison</h2>
        <table>
          <tr>
            <th>Provider</th>
            <th>Free Plan</th>
            <th>Basic Plan</th>
            <th>Premium Plan</th>
          </tr>
          <tr>
            <td>DynIP Space</td>
            <td>3 hosts</td>
            <td>$5/month</td>
            <td>$10/month</td>
          </tr>
          <tr>
            <td>No-IP</td>
            <td>1 host</td>
            <td>$7/month</td>
            <td>$15/month</td>
          </tr>
          <tr>
            <td>DuckDNS</td>
            <td>5 hosts</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </table>

        <h2>Recommendations</h2>
        <p>
          When choosing a DDNS provider, we recommend:
        </p>
        <ul>
          <li>Test free plans</li>
          <li>Check update speed</li>
          <li>Review documentation</li>
          <li>Evaluate support quality</li>
        </ul>
      `,
      meta: {
        description: 'Detailed comparison of popular DDNS providers: functionality, pricing, reliability.',
        keywords: 'DDNS, providers, comparison, pricing, features',
      }
    },

    '5-reasons-use-ddns-home-server': {
      title: '5 Reasons to Use DDNS for Your Home Server',
      image: {
        url: '/images/blog/home-server.jpg',
        alt: 'Home Server',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>1. Constant Service Access</h2>
        <p>
          DDNS ensures stable access to your services even when IP address changes:
        </p>
        <ul>
          <li>File storage</li>
          <li>Web servers</li>
          <li>Media servers (Plex, Emby)</li>
          <li>Surveillance systems</li>
        </ul>

        <h2>2. Static IP Cost Savings</h2>
        <p>
          Using DDNS allows you to:
        </p>
        <ul>
          <li>Avoid additional static IP costs</li>
          <li>Use regular home internet connection</li>
          <li>Maintain all dedicated address benefits</li>
        </ul>

        <h2>3. Easy Setup</h2>
        <p>
          Modern DDNS services offer:
        </p>
        <ul>
          <li>Intuitive interface</li>
          <li>Automatic IP updates</li>
          <li>Ready-made clients for popular devices</li>
          <li>Detailed documentation</li>
        </ul>

        <h2>4. Flexibility and Scalability</h2>
        <p>
          DDNS makes it easy to:
        </p>
        <ul>
          <li>Add new services</li>
          <li>Move server to different connection</li>
          <li>Configure redundancy</li>
          <li>Integrate with other systems</li>
        </ul>

        <h2>5. Professional Monitoring</h2>
        <p>
          Modern DDNS services provide:
        </p>
        <ul>
          <li>Availability monitoring</li>
          <li>IP change notifications</li>
          <li>Usage statistics</li>
          <li>Update logs</li>
        </ul>

        <h2>Conclusion</h2>
        <p>
          DDNS is an optimal solution for organizing access to a home server, 
          combining ease of use, reliability, and cost-effectiveness.
        </p>
      `,
      meta: {
        description: 'Analysis of key benefits of using DDNS for home server.',
        keywords: 'DDNS, home server, hosting, availability',
      }
    },

    'ddns-for-remote-work': {
      title: 'DDNS for Remote Work',
      image: {
        url: '/images/blog/remote-work.jpg',
        alt: 'Remote Work',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>DDNS for Remote Work Organization</h2>
        <p>
          In modern conditions, remote work has become an integral part of business processes. 
          DDNS helps organize reliable and secure access to corporate resources.
        </p>

        <h2>Main Use Cases</h2>
        <ul>
          <li>Access to corporate servers</li>
          <li>Remote workstation connection</li>
          <li>VPN organization</li>
          <li>Access to video conferencing systems</li>
        </ul>

        <h2>Secure Access Setup</h2>
        <p>
          To ensure secure remote access, you need to:
        </p>
        <ol>
          <li>Configure two-factor authentication</li>
          <li>Use VPN</li>
          <li>Restrict access by IP</li>
          <li>Regularly update software</li>
        </ol>

        <h2>Monitoring and Control</h2>
        <p>
          Important monitoring aspects:
        </p>
        <ul>
          <li>Tracking access attempts</li>
          <li>Performance monitoring</li>
          <li>Resource usage control</li>
          <li>Security audit</li>
        </ul>
      `,
      meta: {
        description: 'Guide on organizing remote work using DDNS.',
        keywords: 'DDNS, remote work, VPN, security',
      }
    },

    'ddns-docker-integration': {
      title: 'DDNS Integration with Docker',
      image: {
        url: '/images/blog/docker-integration.jpg',
        alt: 'Docker Integration',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Docker and DDNS</h2>
        <p>
          Integrating DDNS with Docker allows creating flexible and scalable solutions 
          for managing containerized applications.
        </p>

        <h2>Integration Options</h2>
        <ul>
          <li>Using Docker container for DDNS client</li>
          <li>Automatic DNS updates during deployment</li>
          <li>Integration with Docker Swarm</li>
          <li>Working with Kubernetes</li>
        </ul>

        <h2>Docker-compose Example</h2>
        <pre>
version: '3'
services:
  ddns-updater:
    image: ddns-client
    environment:
      - DDNS_HOST=your-host.dynip.space
      - DDNS_TOKEN=your-token
    restart: always
      </pre>

      <h2>Update Automation</h2>
      <p>
        Automation recommendations:
      </p>
      <ul>
        <li>Using CI/CD pipelines</li>
        <li>Container state monitoring</li>
        <li>Automatic recovery from failures</li>
        <li>Operation logging</li>
      </ul>
    `,
      meta: {
        description: 'Practical guide on integrating DDNS with Docker.',
        keywords: 'DDNS, Docker, containers, automation',
      }
    },

    'ddns-monitoring-grafana': {
      title: 'DDNS Monitoring with Grafana',
      image: {
        url: '/images/blog/grafana-monitoring.jpg',
        alt: 'Grafana Monitoring',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>Monitoring Setup</h2>
        <p>
          Grafana allows creating an informative monitoring dashboard for tracking 
          DDNS services operation in real-time.
        </p>

        <h2>Key Metrics</h2>
        <ul>
          <li>DNS server response time</li>
          <li>Record update frequency</li>
          <li>Error statistics</li>
          <li>Resource usage</li>
        </ul>

        <h2>Dashboard Setup</h2>
        <p>
          Main setup steps:
        </p>
        <ol>
          <li>Connecting data sources</li>
          <li>Creating visualization panels</li>
          <li>Setting up alerts</li>
          <li>Display optimization</li>
        </ol>

        <h2>Report Automation</h2>
        <p>
          Automation capabilities:
        </p>
        <ul>
          <li>Regular reports</li>
          <li>Issue notifications</li>
          <li>Data export</li>
          <li>Integration with other systems</li>
        </ul>
      `,
      meta: {
        description: 'Guide on setting up DDNS monitoring using Grafana.',
        keywords: 'DDNS, Grafana, monitoring, visualization',
      }
    },

    'ddns-microservices': {
      title: 'DDNS in Microservices Architecture',
      image: {
        url: '/images/blog/microservices.jpg',
        alt: 'Microservices Architecture',
        copyright: '© 2024 DynIP Space. Image created specifically for this article'
      },
      content: `
        <h2>DDNS in Microservices</h2>
        <p>
          Using DDNS in microservices architecture helps solve routing and service 
          discovery challenges.
        </p>

        <h2>Usage Benefits</h2>
        <ul>
          <li>Dynamic service discovery</li>
          <li>Load balancing</li>
          <li>Simplified scaling</li>
          <li>Increased fault tolerance</li>
        </ul>

        <h2>Service Integration</h2>
        <p>
          Main integration aspects:
        </p>
        <ol>
          <li>Service discovery setup</li>
          <li>Load balancer configuration</li>
          <li>Routing management</li>
          <li>State monitoring</li>
        </ol>

        <h2>Scaling</h2>
        <p>
          Scaling recommendations:
        </p>
        <ul>
          <li>Automatic scaling</li>
          <li>Load distribution</li>
          <li>Geographic replication</li>
          <li>Performance optimization</li>
        </ul>
      `,
      meta: {
        description: 'Using DDNS in modern microservices architecture.',
        keywords: 'DDNS, microservices, architecture, scaling',
      }
    }
  },
  auth: {
    welcome: 'Welcome Back',
    description: 'Sign in to access your dashboard',
    continueWithGoogle: 'Continue with Google',
    or: 'or',
    noAccount: 'Don\'t have an account?',
    createAccount: 'Create an account',
    errors: {
      accountBlocked: 'Your account has been blocked. Please contact support.',
      default: 'An error occurred during login. Please try again.'
    }
  },
  errors: {
    cacheError: 'Cache operation error',
    cache: {
      read: 'Failed to read data from cache',
      write: 'Failed to write data to cache',
      clear: 'Failed to clear cache',
      expired: 'Cache data has expired',
      invalid: 'Invalid data in cache'
    },
    loading: {
      users: 'Error loading users',
      hosts: 'Error loading hosts', 
      data: 'Error loading data'
    },
    network: {
      offline: 'No internet connection',
      timeout: 'Server response timeout',
      server: 'Server error'
    },
    copyFailed: 'Failed to copy IP address',
    invalidIp: 'Invalid IP address format',
    updateFailed: 'Failed to update IP address',
    deleteFailed: 'Failed to delete host',
    loadingHosts: 'Error loading hosts list'
  },
  notifications: {
    admin: {
      userBlocked: 'User blocked successfully',
      userUnblocked: 'User unblocked successfully',
      userDeleted: 'User deleted successfully'
    },
    tagUpdated: 'Tag successfully updated',
    viewSaved: 'View settings saved',
    notesUpdated: 'Notes successfully updated'
  },
  // Table headers
  name: 'Name',
  ip: 'IP Address',
  tag: 'Tag',
  notes: 'Notes',
  lastUpdate: 'Last Update',
  actions: 'Actions'
}; 
import React, { useState, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { HostsList } from './components/HostsList';
import { AccountPage } from './components/AccountPage';
import { v4 as uuidv4 } from 'uuid';
import { Layout } from './components/Layout';
import { HelpPage } from './components/HelpPage';
import { TermsPage } from './components/TermsPage';
import { PrivacyPage } from './components/PrivacyPage';
import { AboutPage } from './components/AboutPage';
import { BlogPage } from './components/BlogPage';
import { AdminPage } from './components/AdminPage';
import { FeaturesPage } from './components/FeaturesPage';
import { LandingPage } from './components/LandingPage';
import { PricingPage } from './components/PricingPage';
import { LocalizationPage } from './components/LocalizationPage';
import { useLocale } from './contexts/LocaleContext';
import { BlogPostPage } from './components/BlogPostPage';
import { PublicLayout } from './components/PublicLayout';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { LoginPage } from './components/LoginPage';
import { BaseLayout } from './components/BaseLayout';
import { AdminRoute } from './components/AdminRoute';
import { toast } from 'react-hot-toast';

const App = () => {
  const { t } = useLocale();

  const AppContent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { signOut } = useAuth();
    const [hosts, setHosts] = useState([
      {
        id: 1,
        name: 'myserver',
        ip: '192.168.1.1',
        lastUpdate: new Date().toISOString(),
      },
      {
        id: 2,
        name: 'webserver',
        ip: '10.0.0.1',
        lastUpdate: new Date().toISOString(),
      },
      {
        id: 3,
        name: 'database',
        ip: '172.16.0.1',
        lastUpdate: new Date().toISOString(),
      }
    ]);

    const [user, setUser] = useState({
      plan: 'Free',
      hostsLimit: 3,
      hostsUsed: hosts.length,
      token: uuidv4()
    });

    const onDeleteHost = async (hostId) => {
      try {
        const hostToDelete = hosts.find(h => h.id === hostId);
        if (!hostToDelete) return;

        const response = await fetch('/delhost', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            hostname: hostToDelete.name,
            uid: user.uid
          })
        });

        if (!response.ok) {
          throw new Error('Failed to delete host');
        }

        setHosts(prevHosts => prevHosts.filter(h => h.id !== hostId));
        setUser(prev => ({
          ...prev,
          hostsUsed: prev.hostsUsed - 1
        }));
        // toast.success(t('notifications.hosts.deleted', { name: hostToDelete.name }));
      } catch (error) {
        // console.error('Error deleting host:', error);
        // toast.error(t('notifications.hosts.deleteError'));
      }
    };

    const handleUpdateHost = (id, newIp, showNotification = true) => {
      setHosts(hosts.map(host => 
        host.id === id 
          ? { ...host, ip: newIp, lastUpdate: new Date().toISOString() }
          : host
      ));
      if (showNotification) {
        toast.success("IP address successfully updated");
      }
    };

    const handleLogout = async () => {
      try {
        await signOut();
        setHosts([]);
        setUser(null);
        navigate('/login');
      } catch (error) {
        console.error('Ошибка при выходе:', error);
        toast.error(t('notifications.error.logout'));
      }
    };

    const handleAddHost = (hostData) => {
      if (!hostData || !hostData.title) {
        console.error('Invalid host data:', hostData);
        toast.error(t('errors.addHost.invalidData'));
        return;
      }

      const newHost = {
        id: Date.now(),
        name: hostData.title.toLowerCase(),
        ip: hostData.ip || '0.0.0.0',
        lastUpdate: new Date().toISOString(),
      };

      setHosts([...hosts, newHost]);
      setUser(prev => ({
        ...prev,
        hostsUsed: prev.hostsUsed + 1
      }));
      // toast.success(t('notifications.hosts.added', { name: newHost.title }));
      toast.success("Host successfully added");
    };

    const handleRevokeToken = () => {
      setUser(prev => ({
        ...prev,
        token: uuidv4()
      }));
      toast.success(t('notifications.account.tokenUpdated'));
    };

    const commonProps = {
      onLogout: handleLogout,
      isAdmin: true
    };

    const hostsListRef = useRef(null);

    const handleOpenAddModal = () => {
      if (hostsListRef.current) {
        hostsListRef.current.openAddModal();
      }
    };

    const hostsProps = {
      hosts,
      onDeleteHost,
      onUpdateHost: handleUpdateHost,
      onAddHost: handleAddHost
    };

    if (location.pathname === '/login') {
      return <LoginPage />;
    }

    if (location.pathname === '/' || 
        location.pathname === '/pricing' || 
        location.pathname === '/features' ||
        location.pathname === '/blog' ||
        location.pathname.startsWith('/blog/') ||
        location.pathname === '/help' ||
        location.pathname === '/about' ||
        location.pathname === '/terms' ||
        location.pathname === '/privacy' ||
        location.pathname === '/localization') {
      
      let content = (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPostPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/localization" element={<LocalizationPage />} />
        </Routes>
      );

      return (
        <BaseLayout>
          <PublicLayout>{content}</PublicLayout>
        </BaseLayout>
      );
    }

    return (
      <BaseLayout>
        <Layout {...commonProps} onAddHost={handleOpenAddModal}>
          <Routes>
            <Route 
              path="/hosts" 
              element={
                <ProtectedRoute>
                  <HostsList 
                    ref={hostsListRef}
                    {...hostsProps} 
                  />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/account" 
              element={
                <ProtectedRoute>
                  <AccountPage
                    user={user}
                    onRevokeToken={handleRevokeToken}
                  />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/admin" 
              element={
                <ProtectedRoute>
                  <AdminRoute>
                    <AdminPage />
                  </AdminRoute>
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Layout>
      </BaseLayout>
    );
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App; 
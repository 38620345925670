import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { ref, get } from 'firebase/database';
import { useLocale } from '../contexts/LocaleContext';
import { TAG_COLORS } from '../constants/colors';
import debug from '../utils/debug';

const validateHostname = (hostname) => {
  if (!hostname) {
    return 'validation.hostname.required';
  }
  if (hostname.length < 3) {
    return 'validation.hostname.tooShort';
  }
  if (hostname.length > 63) {
    return 'validation.hostname.tooLong';
  }
  // Только латинские буквы, цифры и дефис. Дефис не может быть в начале или конце
  if (!/^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/.test(hostname)) {
    return 'validation.hostname.invalid';
  }
  return null;
};

const validateIp = (ip) => {
  if (!ip) return null; // IP может быть пустым
  
  const parts = ip.split('.');
  if (parts.length !== 4) {
    return 'validation.ip.invalid';
  }
  
  for (const part of parts) {
    const num = parseInt(part, 10);
    if (isNaN(num) || num < 0 || num > 255) {
      return 'validation.ip.range';
    }
  }
  return null;
};

const checkHostExists = async (hostname, uid, t) => {
  try {
    const response = await fetch('/checkhost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        hostname
      })
    });

    if (!response.ok) {
      throw new Error(t('hosts.errors.addHost.checkFailed'));
    }

    const data = await response.json();
    return data.exists;
  } catch (err) {
    console.error('Error checking hostname:', err);
    throw new Error(t('hosts.errors.addHost.checkFailed'));
  }
};

export const AddHostModal = ({ onClose, onAdd }) => {
  const { t } = useLocale();
  const { user } = useAuth();
  const [title, setTitle] = useState('');
  const [ip, setIp] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [hostsCount, setHostsCount] = useState(0);
  const [userToken, setUserToken] = useState(null);
  const [hostnameError, setHostnameError] = useState('');
  const [ipError, setIpError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tag, setTag] = useState('');
  const [tagColor, setTagColor] = useState('blue');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Получаем данные пользователя
        const userRef = ref(db, `users/${user.uid}`);
        const userSnapshot = await get(userRef);
        const userData = userSnapshot.val();
        
        // Получаем план и токен
        const level = userData?.level || 'free';
        setUserPlan(level.toLowerCase());
        setUserToken(userData?.token);

        // Получаем количество хостов пользователя
        const userPostsRef = ref(db, `user-posts/${user.uid}`);
        const postsSnapshot = await get(userPostsRef);
        const count = postsSnapshot.exists() ? Object.keys(postsSnapshot.val()).length : 0;
        setHostsCount(count);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data');
      }
    };

    fetchUserData();
  }, [user?.uid]);

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);
    const error = validateHostname(value);
    setHostnameError(error ? t(error) : '');
  };

  const handleIpChange = (e) => {
    const value = e.target.value;
    setIp(value);
    const error = validateIp(value);
    setIpError(error ? t(error) : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      // Проверяем существование хоста через API
      const exists = await checkHostExists(title, user.uid, t);
      if (exists) {
        throw new Error(t('hosts.errors.addHost.hostExists'));
      }

      debug('Adding new host:', { hostname: title, ip });

      const response = await fetch('/addhost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hostname: title,
          ip: ip || '',
          uid: user.uid,
          token: userToken,
          tag: tag,
          tagColor: tagColor,
          notes: notes
        })
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      await onAdd({ 
        title: title,
        ip: ip || '',
        tag: tag,
        tagColor: tagColor
      });
      onClose();
    } catch (error) {
      debug('Error adding host:', error);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">{t('modals.addHost.title')}</h2>
      
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {error}
        </div>
      )}

      {/* Показываем информацию о лимите */}
      {/* <div className="text-sm text-gray-600 mb-4">
        {t('modals.addHost.hostsCount', { 
          current: hostsCount, 
          limit: userPlan === 'pro' ? 100 : 3,
          interpolation: { escapeValue: false }
        })}
      </div> */}

      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('modals.addHost.name.label')}
            </label>
            <div className="flex rounded-md shadow-sm">
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                placeholder={t('modals.addHost.name.placeholder')}
                className={`flex-1 block w-full rounded-l-md border ${
                  hostnameError ? 'border-red-300' : 'border-gray-300'
                } px-3 py-2 focus:outline-none focus:ring-2 ${
                  hostnameError ? 'focus:ring-red-500' : 'focus:ring-blue-500'
                }`}
              />
              <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                .dynip.space
              </span>
            </div>
            {hostnameError && (
              <p className="mt-1 text-sm text-red-600">{hostnameError}</p>
            )}
            <p className="mt-1 text-sm text-gray-500">
              {t('modals.addHost.name.hint')}
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('modals.addHost.ip.label')}
            </label>
            <input
              type="text"
              value={ip}
              onChange={handleIpChange}
              placeholder={t('modals.addHost.ip.placeholder')}
              className={`w-full px-3 py-2 border ${
                ipError ? 'border-red-300' : 'border-gray-300'
              } rounded-lg focus:ring-2 ${
                ipError ? 'focus:ring-red-500' : 'focus:ring-blue-500'
              } focus:border-transparent`}
            />
            {ipError && (
              <p className="mt-1 text-sm text-red-600">{ipError}</p>
            )}
            <p className="mt-1 text-sm text-gray-500">
              {t('modals.addHost.ip.hint')}
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('modals.addHost.tag.label')}
            </label>
            <input
              type="text"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              placeholder={t('modals.addHost.tag.placeholder')}
              className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="mt-2 flex gap-2 items-center">
              {TAG_COLORS.map(color => (
                <button
                  key={color.id}
                  type="button"
                  onClick={() => setTagColor(color.id)}
                  className={`w-6 h-6 rounded-full ${color.bg} ${
                    tagColor === color.id ? 'ring-2 ring-offset-2 ring-gray-400' : ''
                  }`}
                  title={color.label}
                />
              ))}
            </div>
            <p className="mt-1 text-sm text-gray-500">
              {t('modals.addHost.tag.hint')}
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('modals.addHost.notes.label')}
            </label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder={t('modals.addHost.notes.placeholder')}
              className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 h-24"
            />
            <p className="mt-1 text-sm text-gray-500">
              {t('modals.addHost.notes.hint')}
            </p>
          </div>
        </div>

        {isSubmitting && (
          <div className="mb-4">
            <div className="h-1 w-full bg-gray-100 rounded-full overflow-hidden">
              <div className="h-full bg-blue-500 rounded-full animate-progress" />
            </div>
            <p className="text-sm text-gray-500 mt-2">Adding host...</p>
          </div>
        )}

        <div className="mt-6 flex justify-end gap-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
            disabled={isSubmitting}
          >
            {t('modals.addHost.buttons.cancel')}
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={isSubmitting || !!hostnameError || !!ipError}
          >
            {isSubmitting ? t('modals.addHost.buttons.adding') : t('modals.addHost.buttons.add')}
          </button>
        </div>
      </form>
    </div>
  );
}; 